"use client"

import { Loader } from "oddsgate-ds";
import { styled } from "styled-components";
import { useGlobalContext } from "@/lib/globalContext";
import { useLayoutEffect } from "react";

const StyledGrid = styled.div`
  display:flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100dvh;
`;

export default function Loading() {
  const { lenis } = useGlobalContext();

  useLayoutEffect(() => {
    lenis?.scrollTo(0, { immediate: true, force: true });
  }, [lenis]);

  // You can add any UI inside Loading, including a Skeleton.
  return <StyledGrid>
    <Loader></Loader>
  </StyledGrid>
}